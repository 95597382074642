




















import Vue from 'vue'
import { mapState } from 'vuex'
import { SIZES as SECTION_SIZES } from '@/components/AppSection.vue'
import DerivativeInfo, { NAME as AMOUNT_STATISTIC_NAME } from './DerivativeInfo.vue'
import { SelectOption } from '@/models/form'
import { DerivativeInfoType, DerivativeViewType } from './types'

export default Vue.extend({
  data(): {
    perPage: number
    isShowAsk: boolean
    isShowBid: boolean
    derivativeInfos?: DerivativeInfoType
    derivativeView: DerivativeViewType
    refreshViewScheduler: any
  } {
    return {
      perPage: 20,
      isShowAsk: true,
      isShowBid: true,
      derivativeInfos: undefined,
      derivativeView: { bids: [], asks: [] },
      refreshViewScheduler: null
    }
  },

  components: {
    DerivativeInfo
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair', 'market']),

    SECTION_SIZES() {
      return SECTION_SIZES
    },

    AMOUNT_STATISTIC_NAME() {
      return AMOUNT_STATISTIC_NAME
    },

    PER_PAGE_OPTIONS(): SelectOption[] {
      return [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' }
      ]
    },

    isBitmexExchange(): boolean {
      return ['bitmex', 'bitmexTestnet'].includes(this.exchange)
    },

    underlying(): number {
      return Number(this.pair?.underlyingToPositionMultiplier || 1)
    },

    derivativeInfoData(): DerivativeInfoType | undefined {
      if (this.derivativeInfos) {
        if (this.isBitmexExchange && this.pair?.underlyingToPositionMultiplier) {
          return {
            ...this.derivativeInfos,
            bids: this.derivativeInfos.bids.map((item) => [item[0], item[1] / this.underlying]),
            asks: this.derivativeInfos.asks.map((item) => [item[0], item[1] / this.underlying])
          }
        } else {
          return this.derivativeInfos
        }
      }
      return undefined
    },

    currentExchange(): string {
      if (this.exchange === 'binanceMarginCross') return 'binance'
      return this.exchange
    }
  },

  methods: {
    filterAmount(filterKey: AMOUNT_STATISTIC_NAME) {
      switch (filterKey) {
        case AMOUNT_STATISTIC_NAME.BIDS:
          this.isShowAsk = false
          this.isShowBid = true
          break
        case AMOUNT_STATISTIC_NAME.ASKS:
          this.isShowAsk = true
          this.isShowBid = false
          break
        default:
          this.isShowAsk = true
          this.isShowBid = true
          break
      }
    },

    refreshView() {
      this.derivativeView.bids = this.derivativeInfoData?.bids.slice(0, this.perPage - 1) || []
      this.derivativeView.asks = this.derivativeInfoData?.asks.slice(0, this.perPage - 1) || []
    },

    connectSocket() {
      this.derivativeInfos = undefined
      this.$socket.open()

      this.$socket.on('REALTIME_ORDERBOOK', (data: DerivativeInfoType) => {
        if (data.exchange === this.currentExchange && data.symbol === this.pair?.symbol) {
          this.derivativeInfos = data
        }
      })
    }
  },

  created() {
    this.connectSocket()
  },

  watch: {
    perPage() {
      this.refreshView()
    },

    exchange() {
      this.connectSocket()
    },

    pair: {
      handler() {
        if (!this.pair) {
          this.derivativeInfos = undefined
        } else {
          this.connectSocket()
        }
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.$socket.disconnect()
    clearInterval(this.refreshViewScheduler)
  },

  mounted() {
    this.refreshViewScheduler = setInterval(() => {
      this.refreshView()
    }, 600)
  }
})
