

































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Derivative } from './types'

export enum NAME {
  ASKS = 'ASKS',
  BIDS = 'BIDS',
  ALL = 'ALL'
}

export default Vue.extend({
  props: {
    name: {
      type: String as () => NAME,
      default: NAME.ASKS
    },

    value: {
      type: Array as () => Derivative[],
      default() {
        return { message: [] }
      }
    }
  },

  computed: {
    ...mapGetters('exchange', ['priceDecimal', 'sizeDecimal']),

    isAsks(): boolean {
      return this.name === NAME.ASKS
    },

    isBids(): boolean {
      return this.name === NAME.BIDS
    }
  }
})
